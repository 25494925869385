import React, { useState } from "react";
import { Card, Tab, Nav } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import VerificationRequestsTable from "./VerificationRequestsTable";
import UsersTable from "./UsersTable";
import { useLocation } from "react-router-dom";
import FaceVerification from "./FaceVerification";
import ReuploadFaceVerification from "./ReuploadFaceVerification";
import ReuploadProfileVerification from "./ReuploadProfileVerification";

export default function FaceTabs() {
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState(
    location?.state?.selectedTab ? location?.state?.selectedTab : "Re Upload"
  );

  const tabData = [
    { name: "Re Upload", component: <ReuploadFaceVerification /> },
    { name: "Re Upload Profile", component: <ReuploadProfileVerification /> },
    { name: "Face Verification", component: <FaceVerification /> },
    { name: "Verification Requests", component: <VerificationRequestsTable /> },
  ];

  return (
    <div>
      <PageTitle
        activeMenu="Face Verification Requested"
        motherMenu="Face Verification"
      />
      <Card>
        <Card.Body>
          <div className="default-tab">
            <Tab.Container defaultActiveKey={selectedTab}>
              <Nav as="ul" className="nav-tabs">
                {tabData.map((data, i) => (
                  <Nav.Item as="li" key={i}>
                    <Nav.Link eventKey={data.name}>{data.name}</Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
              <Tab.Content className="pt-3">
                {tabData.map((data, i) => (
                  <Tab.Pane
                    eventKey={data.name}
                    onClick={() => setSelectedTab(data.name)}
                    key={i}
                  >
                    {data.component}
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Tab.Container>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}
