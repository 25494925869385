import React, { useEffect, useRef, useState } from "react";
import PageTitle from "../layouts/PageTitle";
import { notifyError, notifyTopRight } from "../components/Common/Toaster";
import { postNotification } from "../../services/PushNotification/NotificationService";
import ButtonLoader from "../components/Common/ButtonLoader/ButtonLoader";
import addPhoto from "../../services/Auth/AuthService";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Editor } from "@tinymce/tinymce-react";
import Select from "react-select/async";
import { getUserList } from "../../services/UserServices/UserService";

export default function Notification({ updateNotification, setUpdateNotification }) {
  let errorsObj = {
    title: "",
    title_ch: "",
    message: "",
    message_ch: "",
    type: "",
    sendTo: "",
    websiteUrl: "",
    buttonText: "",
  };
  const formRef = useRef(null);
  const [errors, setErrors] = useState(errorsObj);
  const [formData, setFormData] = useState({
    title: "",
    title_ch: "",
    message: "",
    message_ch: "",
    type: "all",
    selectedUser: "",
    websiteUrl: "",
    buttonText: "",
    image: "",
    sendTo: "",
    isPinned: false,
  });

  const [loader, setLoader] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState(null);

  const handleChangeText = (content) => {
    setFormData({ ...formData, message: content });
    setErrors({ ...errors, message: "" });
  };
  const handleChangeTextCh = (content) => {
    setFormData({ ...formData, message_ch: content });
    setErrors({ ...errors, message_ch: "" });
  };
  const handleInput = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
    setErrors({ ...errors, [name]: "" });
  };

  console.log(selectedUsers, "formData");
  //// function to post data ///
  const onSubmit = async (e) => {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (formData?.title.trim() === "") {
      errorObj.title = "Title is Required";
      error = true;
    }
    if (formData?.title_ch.trim() === "") {
      errorObj.title_ch = "Title is Required";
      error = true;
    }
    if (formData?.message.trim() === "") {
      errorObj.message = "Body is Required";
      error = true;
    }
    if (formData?.message_ch.trim() === "") {
      errorObj.message_ch = "Body is Required";
      error = true;
    }
    setErrors(errorObj);

    if (error) {
      return;
    }

    setLoader(true);

    console.log(formData, "formData");

    try {
      const dataToSend = {
        ...formData,
        selectedUser: selectedUsers !== null ? selectedUsers.value : "",
      };

      const response = await postNotification(dataToSend);
      notifyTopRight("Sent Successfully.");
      setUpdateNotification(true);

      handleChangeText("");
      handleChangeTextCh("");

      setFormData({
        title: "",
        title_ch: "",
        message: "",
        message_ch: "",
        type: "all",
        image: "",
        sendTo: "",
        websiteUrl: "",
        buttonText: "",
        selectedUser: "",
        isPinned: false,
      });

      setSelectedUsers(null);

      // Reset the file input separately
      if (formRef.current) {
        formRef.current.querySelector('input[type="file"]').value = "";
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      notifyError("Something went wrong please try again!");
    }
  };
  const albumName = "notificationImage";

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "image") {
      const selectedFile = e.target.files[0]; // Get the first selected file

      if (selectedFile) {
        addPhoto(selectedFile, albumName)
          .then((response) => {
            console.log(response, "image response"); // Log the response
            const imageName = response?.key;

            if (imageName) {
              setFormData({
                ...formData,
                image: imageName,
              });
            }
          })
          .catch((error) => {
            console.log(error, "image upload error");
          });
      }
    }
  };

  const loadUsers = async (inputValue) => {
    try {
      const response = await getUserList(
        0,
        20,
        inputValue,
        "all",
        "all",
        "all",
        "all",
        "createdAt",
        null,
        null
      );

      return response.data.data.users.map((user) => ({
        value: user._id,
        label: `${user.name} (${user.email || "No email"})`,
      }));
    } catch (error) {
      console.error("Error loading users:", error);
      return [];
    }
  };

  return (
    <div>

      <div className="">
        <div className="authincation-content text-black p-5">
          <div className="mb-4">
            <h3 className="mb-1 font-w600 text-black ">Add Details</h3>
          </div>

          <form onSubmit={onSubmit} ref={formRef}>
            <div className="form-group mb-3">
              <label className="text-black font-w500">Image</label>
              <div className="contact-name">
                <input
                  type="file"
                  accept="image/*"
                  className="form-control"
                  name="image"
                  onChange={(e) => handleInputChange(e)}
                />
                <span className="validation-text"></span>
                {errors.image && (
                  <div className="text-danger fs-12">{errors.image}</div>
                )}
              </div>
            </div>
            <div className="form-group">
              <label className="mb-2 ">
                <strong className="">Title(English)</strong>
              </label>
              <input
                type="text"
                className="form-control"
                name="title"
                value={formData.title}
                onChange={handleInput}
                placeholder="Enter title"
              />
              {errors.title && (
                <div className="text-danger fs-12">{errors.title}</div>
              )}
            </div>
            <div className="form-group">
              <label className="mb-2 ">
                <strong className="">Title(Chinese)</strong>
              </label>
              <input
                type="text"
                className="form-control"
                name="title_ch"
                value={formData.title_ch}
                onChange={handleInput}
                placeholder="Enter title"
              />
              {errors.title_ch && (
                <div className="text-danger fs-12">{errors.title_ch}</div>
              )}
            </div>
            <div className="form-group">
              <label className="mb-2 ">
                <strong className="">Body(English)</strong>
              </label>
              <Editor
                apiKey="vurzvkgq1arl9j83l1910dxu02h5tculb0q9ygmaoxddaxx1"
                init={{
                  height: 300,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image code charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code textcolor wordcount",
                    "textarea",
                    "textcolor",
                    "forecolor backcolor",
                    "link"
                  ],
                  toolbar: [
                    "undo redo | formatselect | code | link | image | bold italic backcolor underline | alignleft aligncenter alignright alignjustify",
                    "bullist numlist outdent indent | textcolor | textarea | forecolor backcolor",
                  ],
                  content_style: "body { color: #000 }",
                  link_default_target: '_blank',
                  link_assume_external_targets: true,
                  link_title: true,
                  link_context_toolbar: true,
                  link_rel_list: [
                    { title: 'None', value: '' },
                    { title: 'No follow', value: 'nofollow' },
                    { title: 'No referrer', value: 'noreferrer' }
                  ]
                }}
                onEditorChange={handleChangeText}
                name="prescription"
                value={formData.message}
              />
              {errors.message && (
                <div className="text-danger fs-12">{errors.message}</div>
              )}
            </div>
            <div className="form-group">
              <label className="mb-2 ">
                <strong className="">Body(Chinese)</strong>
              </label>
              <Editor
                apiKey="vurzvkgq1arl9j83l1910dxu02h5tculb0q9ygmaoxddaxx1"
                init={{
                  height: 300,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image code charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code textcolor wordcount",
                    "textarea",
                    "textcolor",
                    "forecolor backcolor",
                    "link"
                  ],
                  toolbar: [
                    "undo redo | formatselect | code | link | image | bold italic backcolor underline | alignleft aligncenter alignright alignjustify",
                    "bullist numlist outdent indent | textcolor | textarea | forecolor backcolor",
                  ],
                  content_style: "body { color: #000 }",
                  link_default_target: '_blank',
                  link_assume_external_targets: true,
                  link_title: true,
                  link_context_toolbar: true,
                  link_rel_list: [
                    { title: 'None', value: '' },
                    { title: 'No follow', value: 'nofollow' },
                    { title: 'No referrer', value: 'noreferrer' }
                  ]
                }}
                onEditorChange={handleChangeTextCh}
                name="prescription"
                value={formData.message_ch}
              />

              {errors.message_ch && (
                <div className="text-danger fs-12">{errors.message_ch}</div>
              )}
            </div>

            <div className="form-group">
              <label className="mb-2 ">
                <strong className="">Send To</strong>
              </label>
              <select
                className="form-control"
                name="type"
                value={formData.type}
                onChange={handleInput}
              >
                <option value={"all"}>All</option>
                <option value={"male"}>Males</option>
                <option value={"female"}>Females</option>
                <option value={"premium"}>Premium</option>
                <option value={"nonPremium"}>Non Premium</option>
              </select>

              {errors.type && (
                <div className="text-danger fs-12">{errors.type}</div>
              )}
            </div>

            <div className="form-group">
              <label className="mb-2 ">
                <strong className="">Type</strong>
              </label>
              <select
                className="form-control"
                name="sendTo"
                value={formData.sendTo}
                onChange={handleInput}
              >
                <option value={"okay"}>Default</option>
                <option value={"website"}>Website</option>
                <option value={"userProfile"}>User Profile</option>
                <option value={"becomeMember"}>Become Member</option>
                <option value={"personalisation"}>Personal lisation</option>
              </select>

              {errors.type && (
                <div className="text-danger fs-12">{errors.type}</div>
              )}
            </div>

            {formData.sendTo === "userProfile" && (
              <div className="form-group">
                <label className="mb-2">
                  <strong className="">Select Users</strong>
                </label>
                <Select
                  // isMulti
                  cacheOptions
                  defaultOptions
                  value={selectedUsers}
                  onChange={setSelectedUsers}
                  loadOptions={loadUsers}
                  placeholder="Search and select users..."
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </div>
            )}

            {formData.sendTo === "website" && (
              <div className="form-group">
                <label className="mb-2">
                  <strong className="">Website Url</strong>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="websiteUrl"
                  value={formData.websiteUrl}
                  onChange={handleInput}
                  placeholder="Enter website url"
                />
              </div>
            )}

            <div className="form-group">
              <label className="mb-2">
                <strong className="">Button Text</strong>
              </label>
              <input
                type="text"
                className="form-control"
                name="buttonText"
                value={formData.buttonText}
                onChange={handleInput}
                placeholder="Enter button text"
              />
            </div>

            <div className="form-group">
              <div
                className="custom-control custom-checkbox d-flex align-items-center gap-2 p-2"
                style={{
                  background: "#f8f9fa",
                  borderRadius: "8px",
                  border: "1px solid #e9ecef",
                }}
              >
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="isPinned"
                  name="isPinned"
                  checked={formData.isPinned}
                  onChange={handleInput}
                  style={{
                    width: "18px",
                    height: "18px",
                    cursor: "pointer",
                  }}
                />
                <label
                  className="custom-control-label d-flex align-items-center gap-2 mb-0"
                  htmlFor="isPinned"
                  style={{ cursor: "pointer" }}
                >
                  <i className="fas fa-thumbtack me-1"></i>
                  Pin this notification
                  {formData.isPinned && (
                    <span
                      className="badge bg-primary"
                      style={{ fontSize: "0.75rem" }}
                    >
                      Pinned
                    </span>
                  )}
                </label>
              </div>
            </div>

            <div className="text-center">
              <button
                type="submit"
                className="btn btn-primary btn-block"
                disabled={loader}
              >
                {loader ? (
                  <>
                    <ButtonLoader /> Send
                  </>
                ) : (
                  "Send"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
