import React, { useEffect, useRef, useState } from "react";
import PageTitle from "../layouts/PageTitle";
import { notifyError, notifyTopRight } from "../components/Common/Toaster";
import ButtonLoader from "../components/Common/ButtonLoader/ButtonLoader";
import addPhoto from "../../services/Auth/AuthService";
import Select from "react-select/async";
import { getUserList } from "../../services/UserServices/UserService";
import { postEmailNotification } from "../../services/PushNotification/NotificationService";

export default function EmailNotification({
  updateNotification,
  setUpdateNotification,
}) {
  let errorsObj = {
    headers: "",
    templateId: "",
    sendedTo: "",
  };
  const formRef = useRef(null);
  const [errors, setErrors] = useState(errorsObj);
  const [formData, setFormData] = useState({
    headers: "",
    templateId: "",
    sendedTo: "",
    isPreview: false,
  });

  const [loader, setLoader] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState(null);

  const handleChangeText = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  const handleInput = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
    setErrors({ ...errors, [name]: "" });
  };

  console.log(selectedUsers, "formData");
  //// function to post data ///
  const onSubmit = async (e) => {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };

    if (formData?.headers.trim() === "") {
      errorObj.headers = "Headers is Required";
      error = true;
    }
    if (formData?.templateId.trim() === "") {
      errorObj.templateId = "Template ID is Required";
      error = true;
    }
    if (formData?.sendedTo.trim() === "") {
      errorObj.sendedTo = "Send To is Required";
      error = true;
    }
    setErrors(errorObj);

    if (error) {
      return;
    }

    setLoader(true);

    try {
      const response = await postEmailNotification(formData);
      notifyTopRight("Sent Successfully.");
      setUpdateNotification(true);

      setFormData({
        headers: "",
        templateId: "",
        sendedTo: "",
        isPreview: false,
      });

      setLoader(false);
    } catch (error) {
      setLoader(false);
      notifyError("Something went wrong please try again!");
    }
  };

  return (
    <div>
      <div className="">
        <div className="authincation-content text-black p-5">
          <div className="mb-4">
            <h3 className="mb-1 font-w600 text-black ">Add Details</h3>
          </div>

          <form onSubmit={onSubmit} ref={formRef}>
            <div className="form-group">
              <label className="mb-2">
                <strong className="">Template ID</strong>
              </label>
              <input
                type="text"
                className="form-control"
                name="templateId"
                value={formData.templateId}
                onChange={handleInput}
                placeholder="Enter template ID"
              />
              {errors.templateId && (
                <div className="text-danger fs-12">{errors.templateId}</div>
              )}
            </div>

            <div className="form-group">
              <label className="mb-2">
                <strong className="">Headers</strong>
              </label>
              <input
                type="text"
                className="form-control"
                name="headers"
                value={formData.headers}
                onChange={handleInput}
                placeholder="Enter headers"
              />
              {errors.headers && (
                <div className="text-danger fs-12">{errors.headers}</div>
              )}
            </div>

            <div className="form-group">
              <label className="mb-2">
                <strong className="">Send To</strong>
              </label>
              <select
                className="form-control"
                name="sendedTo"
                value={formData.sendedTo}
                onChange={handleInput}
              >
                <option value="">Select Option</option>
                <option value="all">All</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="premium">Premium</option>
                <option value="nonPremium">Non Premium</option>
                <option value="deactivatedMale">Deactivated Male</option>
                <option value="deactivatedFemales">Deactivated Females</option>
                <option value="deletedMale">Deleted Male</option>
                <option value="deletedFemale">Deleted Female</option>
                <option value="pastMembers">Past Members</option>
              </select>
              {errors.sendedTo && (
                <div className="text-danger fs-12">{errors.sendedTo}</div>
              )}
            </div>

            <div className="form-group">
              <div
                className="custom-control custom-checkbox d-flex align-items-center gap-2 p-2"
                style={{
                  background: "#f8f9fa",
                  borderRadius: "8px",
                  border: "1px solid #e9ecef",
                }}
              >
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="isPreview"
                  name="isPreview"
                  checked={formData.isPreview}
                  onChange={handleInput}
                  style={{
                    width: "18px",
                    height: "18px",
                    cursor: "pointer",
                  }}
                />
                <label
                  className="custom-control-label d-flex align-items-center gap-2 mb-0"
                  htmlFor="isPreview"
                  style={{ cursor: "pointer" }}
                >
                  Preview this notification
                </label>
              </div>
            </div>

            <div className="text-center">
              <button
                type="submit"
                className="btn btn-primary btn-block"
                disabled={loader}
              >
                {loader ? (
                  <>
                    <ButtonLoader /> Send
                  </>
                ) : (
                  "Send"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
