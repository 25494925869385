import React, { useState, useEffect } from "react";
import { Table, Dropdown } from "react-bootstrap";
import Spinner from "../components/Common/Spinner";
import { notifyError, notifyTopRight } from "../components/Common/Toaster";
import Pagination from "../components/Common/Pagination";
import { useLocation } from "react-router-dom";
import { deleteNotificationHistory, getNotificationHistory, putNotificationHistory } from "../../services/PushNotification/NotificationService";

const NotificationHistory = ({ updateNotification, setUpdateNotification }) => {
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [listLength, setListLength] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(
    location?.state?.page ? location?.state?.page : 0
  );

  const [initialFetch, setInitialFetch] = useState(true);
  const limit = 10;

  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  const getTableData = async () => {
    setLoader(true);
    try {
      const response = await getNotificationHistory(currentPage, limit);
      setUsers(response.data.data);
      setLoader(false);
      setInitialFetch(false);
    } catch (error) {
      console.log(error, "error");
      setLoader(false);
    }
  };

  const onDelete = async (id) => {
    setLoader(true);
    try {
      const response = await deleteNotificationHistory(id);
      notifyTopRight(response.data?.message);
      getTableData();
      setLoader(false);
    } catch (error) {
      notifyError(error.response?.data?.message);
      console.log(error.response, "error");
      setLoader(false);
    }
  };

  const onAction = async (formData) => {
    setLoader(true);
    try {
      const response = await putNotificationHistory(formData);
      notifyTopRight(response.data?.message);
      getTableData();
      setLoader(false);
    } catch (error) {
      notifyError(error.response?.data?.message);
      console.log(error.response, "error");
      setLoader(false);
    }
  };

  useEffect(() => {
    if (updateNotification) {
      getTableData();
      setUpdateNotification(false);
    }
  }, [currentPage, updateNotification]);

  return loader ? (
    <Spinner />
  ) : (
    <>
      <Table responsive>
        <thead style={{ color: "black" }}>
          <tr>
            <th>
              <strong>Title</strong>
            </th>
            <th>
              <strong>Message</strong>
            </th>
            <th>
              <strong>Pinned</strong>
            </th>
            <th>
              <strong>Action</strong>
            </th>
          </tr>
        </thead>
        <tbody>
          {users?.map((item, i) => (
            <tr
              key={i}
            >
              <td>{item?.title}</td>
              <td dangerouslySetInnerHTML={{ __html: item?.message }} />
              <td>{item?.isPinned ? "Pinned" : "Unpinned"}</td>
              <td
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                {!item?.isDeleted && (
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="info light"
                      className="light sharp btn btn-info i-false"
                    >
                      {svg1}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => onDelete(item._id)}>
                        Delete
                      </Dropdown.Item>
                      {item.isPinned ? <Dropdown.Item onClick={() => onAction({id:item._id,isPinned:false})}>
                        Unpin
                      </Dropdown.Item> : <Dropdown.Item onClick={() => onAction({id:item._id,isPinned:true})}>
                        Pin
                      </Dropdown.Item>}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {users?.length === 0 && !loader && (
        <div className="justify-content-center d-flex my-5">
          Sorry, Data Not Found!
        </div>
      )}
      {users?.length !== 0 && (
        <div className="card-footer clearfix">
          <div className="d-block d-sm-flex justify-content-between align-items-center">
            <div className="dataTables_info">
              Showing {currentPage * limit + 1} to{" "}
              {listLength > (currentPage + 1) * limit
                ? (currentPage + 1) * limit
                : listLength}{" "}
              of {listLength} entries
            </div>
            <Pagination
              pageCount={pageCount}
              pageValue={currentPage}
              setPage={setCurrentPage}
              setUpdateNotification={setUpdateNotification}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default NotificationHistory;
