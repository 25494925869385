import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { notifyError, notifyTopRight } from "../Common/Toaster";
import { approveRejectRequest } from "../../../services/UserServices/UserService";

const ReasonModal = ({ show, id, type, table, onHide }) => {
  const [reason, setReason] = useState("");
  const [errors, setErrors] = useState({ reason: "" });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        userId: id,
        type: type,
        status: "rejected",
        reason: reason,
      };
      await approveRejectRequest(payload);
      notifyTopRight("Rejected Successfully.");
      table();
      onHide();
      setReason("");
    } catch (error) {
      notifyError(error.response?.data?.message || "An error occurred");
      console.error("Error:", error.response || error);
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <form onSubmit={handleSubmit}>
        <div className="modal-header">
          <h4 className="modal-title fs-20">Reason</h4>
        </div>
        <div className="modal-body">
          <div className="form-group mb-3">
            <label className="text-black font-w500">Reason(Optional) </label>
            {/* <textarea
              rows={5}
              className="form-control"
              value={reason}
              onChange={(e) => {
                setReason(e.target.value);
                setErrors({ ...errors, reason: "" });
              }}
            /> */}
            <select
              className="form-control"
              rows={5}
              value={reason}
              onChange={(e) => {
                setReason(e.target.value);
                setErrors({ ...errors, reason: "" });
              }}
            >
              <option value="">Select Reason</option>
              <option
                value={
                  "Your profile picture appears to be of someone else. Please upload a photo that authentically represents you!"
                }
              >
                Your profile picture appears to be of someone else. Please
                upload a photo that authentically represents you!
              </option>
              <option
                value={
                  "Your photo contains inappropriate content. Please upload a respectful image in line with our guidelines."
                }
              >
                Your photo contains inappropriate content. Please upload a
                respectful image in line with our guidelines.
              </option>
              <option
                value={
                  "Your photo didn’t meet our current guidelines; please update it to reappear on Discover!"
                }
              >
                Your photo didn’t meet our current guidelines; please update it
                to reappear on Discover!
              </option>
              <option
                value={
                  "Your photo appears to be a cartoon, avatar, or non-human image. Please upload a real photo of yourself!"
                }
              >
                Your photo appears to be a cartoon, avatar, or non-human image.
                Please upload a real photo of yourself!
              </option>
              <option
                value={
                  "The photo contains text or promotional content. Please upload a personal image for your profile."
                }
              >
                The photo contains text or promotional content. Please upload a
                personal image for your profile.
              </option>
              <option
                value={
                  "Your profile picture has excessive filters or edits. Please upload a more natural photo that represents the real you!"
                }
              >
                Your profile picture has excessive filters or edits. Please
                upload a more natural photo that represents the real you!
              </option>
              <option
                value={
                  "Your face is obscured or covered in the photo. Please upload a picture where your face is clearly visible!"
                }
              >
                Your face is obscured or covered in the photo. Please upload a
                picture where your face is clearly visible!
              </option>
              <option
                value={
                  "As a last option, open text field that we can type manually"
                }
              >
                As a last option, open text field that we can type manually
              </option>
            </select>
            {errors.reason && (
              <div className="text-danger fs-12">{errors.reason}</div>
            )}
          </div>
        </div>
        <div className="modal-footer">
          <button type="button" onClick={onHide} className="btn btn-danger">
            <i className="flaticon-delete-1"></i> Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default ReasonModal;
