import axiosInstance from "../AxiosInstance";

export const POST_NOTIFICATION = "admin/user/notifications";
export const POST_SMS_NOTIFICATION = "admin/user/smsNotification";
export const POST_EMAIL_NOTIFICATION = "admin/user/emailNotification";

export function postNotification(formdata) {
  return axiosInstance.post(POST_NOTIFICATION, formdata);
}

export function postSmsNotification(formdata) {
  return axiosInstance.post(POST_SMS_NOTIFICATION, formdata);
}

export function postEmailNotification(formdata) {
  return axiosInstance.post(POST_EMAIL_NOTIFICATION, formdata);
}

export function getNotificationHistory(formdata) {
  return axiosInstance.get(POST_NOTIFICATION, formdata);
}

export function deleteNotificationHistory(id) {
  return axiosInstance.delete(`${POST_NOTIFICATION}?notificationId=${id}`);
}

export function putNotificationHistory(formData) {
  return axiosInstance.put(`${POST_NOTIFICATION}`,formData);
}
