import React, { useState } from "react";
import { Card, Tab, Nav } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import VerificationRequestsTable from "./VerificationRequestsTable";
import UsersTable from "./UsersTable";
import { useLocation } from "react-router-dom";
import FaceVerification from "./FaceVerification";
import ReuploadFaceVerification from "./ReuploadFaceVerification";
import ReuploadProfileVerification from "./ReuploadProfileVerification";
import Notification from "./Notification";
import NotificationHistory from "./NotificationHistory";
import SmsNotification from "./SmsNotification";
import EmailNotification from "./EmailNotification";

export default function NotificationTabs() {
  const [selectedTab, setSelectedTab] = useState("Notification History");
  const [updateNotification, setUpdateNotification] = useState(true);

  const tabData = [
    { name: "Notification History", component: <NotificationHistory updateNotification={updateNotification} setUpdateNotification={setUpdateNotification} /> },
    { name: "Send Notification", component: <Notification updateNotification={updateNotification} setUpdateNotification={setUpdateNotification} /> },
    { name: "Sms Notification", component: <SmsNotification updateNotification={updateNotification} setUpdateNotification={setUpdateNotification} /> },
    { name: "Email Notification", component: <EmailNotification updateNotification={updateNotification} setUpdateNotification={setUpdateNotification} /> },
  ];

  return (
    <div>
      <PageTitle
        activeMenu="Notification"
        motherMenu="Push Notification"
      />
      <Card>
        <Card.Body>
          <div className="default-tab">
            <Tab.Container defaultActiveKey={selectedTab}>
              <Nav as="ul" className="nav-tabs">
                {tabData.map((data, i) => (
                  <Nav.Item as="li" key={i}>
                    <Nav.Link eventKey={data.name}>{data.name}</Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
              <Tab.Content className="pt-3">
                {tabData.map((data, i) => (
                  <Tab.Pane
                    eventKey={data.name}
                    onClick={() => setSelectedTab(data.name)}
                    key={i}
                  >
                    {data.component}
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Tab.Container>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}
