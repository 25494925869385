import React, { useEffect, useRef, useState } from "react";
import { Badge, Card, Dropdown, Table } from "react-bootstrap";
import Spinner from "../components/Common/Spinner";
import Pagination from "../components/Common/Pagination";
import PageTitle from "../layouts/PageTitle";
import { useNavigate } from "react-router-dom";
import { notifyError, notifyTopRight } from "../components/Common/Toaster";
import ReasonModal from "../components/Modal/ReasonModal";
import FullImage from "../components/Modal/FullImage";
import {
  approveMultiRequest,
  approveRejectRequest,
  getVerificationRequests,
} from "../../services/UserServices/UserService";
import dummmyImg from "../../images/profile/images.jpeg";
export default function FaceVerification() {
  const [loader, setLoader] = useState(false);
  const [status, setStatus] = useState("pending");
  const [gender, setGender] = useState("all");
  const [requests, setRequests] = useState([]);
  const [listLength, setListLength] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [order, setOrder] = useState("mostRecent");
  const [initialFetch, setInitialFetch] = useState(true);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [id, setId] = useState("");
  const [type, setType] = useState("image");
  const imgBaseURl = process.env.REACT_APP_IMAGE_BASEURL;
  const [imageForView, setImageForView] = useState("");
  const [view, setView] = useState(false);
  const navigate = useNavigate();
  const [selectedIds, setSelectedIds] = useState([]);
  const chackbox = document.querySelectorAll(".custom-checkbox input");
  const motherChackBox = document.querySelector("#checkbox1_exam_all");
  const mainCheckBox = useRef();
  const limit = 10;
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  //   console.log(selectedIds,"selectedIds")
  const chackboxFun = (type) => {
    // console.log(motherChackBox, "type");

    for (let i = 0; i < chackbox.length; i++) {
      const element = chackbox[i];
      if (type === "all") {
        if (motherChackBox.checked) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      } else {
        if (!element.checked) {
          motherChackBox.checked = false;
          break;
        } else {
          motherChackBox.checked = true;
        }
      }
    }
  };
  function handleSelectedId(data) {
    // console.log(id," id select")
    const oldData = selectedIds.find((item) => {
      return item == data;
    });
    if (oldData === undefined) {
      setSelectedIds([...selectedIds, data]);
    } else {
      let updatedArr = selectedIds.filter((item) => item !== data);
      console.log(updatedArr, " id removed");
      setSelectedIds([...updatedArr]);
    }
    console.log(oldData, "new id......");
  }

  function handleAllId() {
    const allId = requests?.map((item) => {
      return item;
    });
    if (mainCheckBox?.current?.checked) {
      setSelectedIds([...allId]);
    } else {
      setSelectedIds([]);
    }
  }
  const fetchRequests = async () => {
    setLoader(true);
    try {
      const response = await getVerificationRequests(
        currentPage,
        limit,
        type,
        status,
        gender,
        order
      );
      setRequests(response.data.data?.users);
      const total = response.data.data.count;
      setPageCount(Math.ceil(total / limit));
      setListLength(total);
      setLoader(false);
      setInitialFetch(false);
    } catch (error) {
      console.log(error, "error");
      setLoader(false);
    }
  };

  const onApprove = async (id) => {
    setLoader(true);
    try {
      const payload = { userId: id, type: type, status: "accepted" };
      const response = await approveRejectRequest(payload);
      fetchRequests();
      notifyTopRight(response.data?.message);
      setLoader(false);
    } catch (error) {
      notifyError(error.response?.data?.message);
      console.log(error.response, "error");
      setLoader(false);
    }
  };
  const onMultiApprove = async (id) => {
    setLoader(true);
    try {
      // Extract only _id from selectedIds
      const userIds = selectedIds.map((item) => item._id);
      const payload = { userIds }; // Create payload with just _id array
      const response = await approveMultiRequest(payload);
      setSelectedIds([]);
      fetchRequests();
      notifyTopRight(response.data?.message);
      setLoader(false);
    } catch (error) {
      notifyError(error.response?.data?.message);
      console.log(error.response, "error");
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchRequests();
  }, [currentPage, type]);
  useEffect(() => {
    if ((status, gender, order)) {
      setCurrentPage(0);
      fetchRequests();
    }
    // fetchRequests();
  }, [status, gender, order]);

  return (
    <div>
      <Card>
        <Card.Header>
          {" "}
          <div></div>
          <div className="d-flex gap-2 flex-grow-1 align-items-end">
            <div className="mb-2 mb-md-0 w-100">
              <label className="text-black font-w600 fs-14">
                Filter by Gender
              </label>

              <select
                className="form-control"
                value={gender}
                onChange={(e) => setGender(e.target.value)}
              >
                <option value={"all"}>All</option>
                <option value={"male"}>Male</option>
                <option value={"female"}>Female</option>
              </select>
            </div>
            <div className="mb-2 mb-md-0 w-100">
              <label className="text-black font-w600 fs-14">
                Filter by Status
              </label>
              <select
                className="form-control"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value={"pending"}>Pending</option>
                <option value={"accepted"}>Approved</option>
                <option value={"rejected"}>Rejected</option>
              </select>
            </div>
            <div className="mb-2 mb-md-0 w-100">
              <label className="text-black font-w600 fs-14">
                Filter by Order
              </label>
              <select
                className="form-control"
                value={order}
                onChange={(e) => setOrder(e.target.value)}
              >
                <option value={"all"}>All</option>
                <option value={"mostRecent"}>Most Recent</option>
              </select>
            </div>
            {selectedIds?.length > 0 && (
              <div className="mb-2 mb-md-0 w-100 text-end">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => onMultiApprove()}
                >
                  Approve
                </button>
              </div>
            )}
          </div>
        </Card.Header>
        <Card.Body className="pb-0 pt-3">
          {loader ? (
            <Spinner />
          ) : (
            <Table responsive>
              <thead style={{ color: "black" }}>
                <tr>
                  <th className="">
                    <div className="custom-control custom-checkbox-list checkbox-success check-lg mr-3">
                      <input
                        type="checkbox"
                        ref={mainCheckBox}
                        className="custom-control-input"
                        id="checkbox1_exam_all"
                        onChange={() => {
                          chackboxFun("all");
                          handleAllId();
                        }}
                        required=""
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="checkbox1_exam_all"
                      ></label>
                    </div>
                  </th>

                  <th>
                    <strong>NAME</strong>
                  </th>
                  <th>
                    <strong>Gender</strong>
                  </th>
                  <th>
                    <strong>Profile Pic</strong>
                  </th>
                  <th>
                    <strong>Image</strong>
                  </th>
                  <th>
                    <strong>STATUS</strong>
                  </th>
                  <th>
                    <strong>ACTION</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {requests?.map((item, i) => (
                  <tr key={i}>
                    <td>
                      <div className="custom-control custom-checkbox-list checkbox-success check-lg mr-3">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id={item._id}
                          checked={selectedIds.includes(item)}
                          onChange={() => {
                            chackboxFun();
                            handleSelectedId(item);
                          }}
                        />

                        <label
                          className="custom-control-label"
                          htmlFor={item._id}
                        ></label>
                      </div>
                    </td>

                    <td
                      onClick={() =>
                        navigate("/verification-user-details", {
                          state: {
                            data: item?._id,
                            selectedTab: "Face Verification",
                          },
                        })
                      }
                      className="pointer"
                    >
                      {item?.name}
                    </td>
                    <td
                      onClick={() =>
                        navigate("/verification-user-details", {
                          state: {
                            data: item?._id,
                            selectedTab: "Face Verification",
                          },
                        })
                      }
                      className="pointer"
                    >
                      {item?.gender &&
                        item?.gender?.charAt(0).toUpperCase() +
                          item?.gender?.slice(1)}
                    </td>
                    <td>
                      <img
                        src={
                          item?.images[0]?.url
                            ? imgBaseURl + item?.images[0]?.url
                            : dummmyImg
                        }
                        alt="img"
                        width={100}
                        height={80}
                        className="rounded"
                        onClick={() => {
                          setImageForView(imgBaseURl + item?.images[0]?.url);
                          setView(true);
                        }}
                      />
                    </td>
                    <td className="pointer">
                      <img
                        src={imgBaseURl + item?.verificationImage?.url}
                        alt="img"
                        width={100}
                        height={80}
                        className="rounded"
                        onClick={() => {
                          setImageForView(
                            imgBaseURl + item?.verificationImage?.url
                          );
                          setView(true);
                        }}
                      />
                    </td>

                    <td
                      onClick={() =>
                        navigate("/verification-user-details", {
                          state: {
                            data: item?._id,
                            selectedTab: "Face Verification",
                          },
                        })
                      }
                      className="pointer"
                    >
                      {item?.gender === "male" ? (
                        item?.verificationImage?.isUploadAgain &&
                        item?.verificationImage?.adminAction === "pending" ? (
                          <Badge bg="" className="badge-warning">
                            Again Uploaded
                          </Badge>
                        ) : item?.verificationImage?.status === "pending" ? (
                          <Badge bg="" className="badge-warning">
                            Pending
                          </Badge>
                        ) : item?.verificationImage?.status === "accepted" &&
                          item?.verificationImage?.adminAction === "pending" ? (
                          <Badge bg="" className="badge-warning">
                            Auto Approved
                          </Badge>
                        ) : item?.verificationImage?.status === "accepted" &&
                          item?.verificationImage?.adminAction ===
                            "accepted" ? (
                          <Badge bg="" className="badge-success">
                            Approved
                          </Badge>
                        ) : (
                          <Badge bg="" className="badge-danger">
                            Rejected
                          </Badge>
                        )
                      ) : item?.gender === "female" ? (
                        item?.verificationImage?.isUploadAgain &&
                        item?.verificationImage?.adminAction === "pending" ? (
                          <Badge bg="" className="badge-warning">
                            Again Uploaded
                          </Badge>
                        ) : item?.verificationImage?.status === "pending" ? (
                          <Badge bg="" className="badge-warning">
                            Pending
                          </Badge>
                        ) : item?.verificationImage?.status === "accepted" &&
                          item?.verificationImage?.adminAction === "pending" ? (
                          <Badge bg="" className="badge-warning">
                            Auto Approved
                          </Badge>
                        ) : item?.verificationImage?.status === "accepted" &&
                          item?.verificationImage?.adminAction ===
                            "accepted" ? (
                          <Badge bg="" className="badge-success">
                            Approved
                          </Badge>
                        ) : (
                          <Badge bg="" className="badge-danger">
                            Rejected
                          </Badge>
                        )
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="info light"
                          className="light sharp btn btn-info i-false"
                        >
                          {svg1}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {/* Action items */}
                          {item?.verificationImage?.adminAction ===
                          "pending" ? (
                            <>
                              <Dropdown.Item
                                onClick={() => onApprove(item?._id)}
                              >
                                Approve
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => {
                                  setShowRejectModal(true);
                                  setId(item?._id);
                                }}
                              >
                                Reject
                              </Dropdown.Item>
                            </>
                          ) : item?.verificationImage?.adminAction ===
                            "accepted" ? (
                            <Dropdown.Item
                              onClick={() => {
                                setShowRejectModal(true);
                                setId(item?._id);
                              }}
                            >
                              Reject
                            </Dropdown.Item>
                          ) : (
                            <Dropdown.Item onClick={() => onApprove(item?._id)}>
                              Approve
                            </Dropdown.Item>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
          {requests?.length === 0 && !loader && (
            <div className="justify-content-center d-flex my-5">
              Sorry, Data Not Found!
            </div>
          )}
          {requests?.length !== 0 && (
            <div className="card-footer clearfix">
              <div className="d-block d-sm-flex justify-content-between align-items-center">
                <div className="dataTables_info">
                  Showing {currentPage * limit + 1} to{" "}
                  {listLength > (currentPage + 1) * limit
                    ? (currentPage + 1) * limit
                    : listLength}{" "}
                  of {listLength} entries
                </div>
                <Pagination
                  pageCount={pageCount}
                  pageValue={currentPage}
                  setPage={setCurrentPage}
                />
              </div>
            </div>
          )}
        </Card.Body>
      </Card>
      {showRejectModal && (
        <ReasonModal
          show={showRejectModal}
          id={id}
          type={type}
          table={fetchRequests}
          onHide={() => setShowRejectModal(false)}
        />
      )}
      {view && (
        <FullImage
          show={view}
          image={imageForView}
          onHide={() => setView(false)}
        />
      )}
    </div>
  );
}
