import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { notifyError, notifyTopRight } from "../Common/Toaster";
import {
  approveRejectRequest,
  updateImages,
} from "../../../services/UserServices/UserService";

const ReasonForPicDelete = ({ show, id, images, table, onHide }) => {
  const [reason, setReason] = useState("");
  const [textMessage, setTextMessage] = useState("");
  const [errors, setErrors] = useState({ reason: "", textMessage: "" });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const payload = {
          userId: id,
          images: images,
          reasonsToDeleteImages:
            reason ===
            "other"
              ? textMessage
              : reason,
        };
        await updateImages(payload);
        notifyTopRight("Updated Successfully.");
        table();
        onHide();
        setReason("");
      } catch (error) {
        notifyError(error.response?.data?.message || "An error occurred");
        console.error("Error:", error.response || error);
      }
    }
  };

  const validateForm = () => {
    let isValid = true;
    const errorObj = { reason: "", textMessage: "" };

    if (!reason) {
      errorObj.reason = "Reason is required";
      isValid = false;
    }
    if (
      reason ===
        "other" &&
      !textMessage
    ) {
      errorObj.textMessage = "Reason is required";
      isValid = false;
    }

    setErrors(errorObj);
    return isValid;
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <form onSubmit={handleSubmit}>
        <div className="modal-header">
          <h4 className="modal-title fs-20">Reason</h4>
        </div>
        <div className="modal-body">
          <div className="form-group mb-3">
            <label className="text-black font-w500">Reason </label>
            {/* <textarea
            rows={5}
            className="form-control"
            value={reason}
            onChange={(e) => {
              setReason(e.target.value);
              setErrors({ ...errors, reason: "" });
            }}
          /> */}
            <select
              className="form-control"
              rows={5}
              value={reason}
              onChange={(e) => {
                setReason(e.target.value);
                setErrors({ ...errors, reason: "" });
              }}
            >
              <option value="">Select Reason</option>
              <option
                value={
                  "Your profile picture appears to be of someone else. Please upload a photo that authentically represents you!"
                }
              >
                Your profile picture appears to be of someone else. Please
                upload a photo that authentically represents you!
              </option>
              <option
                value={
                  "Your photo contains inappropriate content. Please upload a respectful image in line with our guidelines."
                }
              >
                Your photo contains inappropriate content. Please upload a
                respectful image in line with our guidelines.
              </option>
              <option
                value={
                  "Your photo didn’t meet our current guidelines; please update it to reappear on Discover!"
                }
              >
                Your photo didn’t meet our current guidelines; please update it
                to reappear on Discover!
              </option>
              <option
                value={
                  "Your photo appears to be a cartoon, avatar, or non-human image. Please upload a real photo of yourself!"
                }
              >
                Your photo appears to be a cartoon, avatar, or non-human image.
                Please upload a real photo of yourself!
              </option>
              <option
                value={
                  "The photo contains text or promotional content. Please upload a personal image for your profile."
                }
              >
                The photo contains text or promotional content. Please upload a
                personal image for your profile.
              </option>
              <option
                value={
                  "Your profile picture has excessive filters or edits. Please upload a more natural photo that represents the real you!"
                }
              >
                Your profile picture has excessive filters or edits. Please
                upload a more natural photo that represents the real you!
              </option>
              <option
                value={
                  "Your face is obscured or covered in the photo. Please upload a picture where your face is clearly visible!"
                }
              >
                Your face is obscured or covered in the photo. Please upload a
                picture where your face is clearly visible!
              </option>
              <option
                value={
                  "As we're a luxury dating app, please use a photo which best represents yourself in a luxury setting, or your account may be deactivated. Thank you!"
                }
              >
                As we're a luxury dating app, please use a photo which best
                represents yourself in a luxury setting, or your account may be
                deactivated. Thank you!
              </option>
              <option
                value={
                  "other"
                }
              >
                Other
              </option>
            </select>
            {errors.reason && (
              <div className="text-danger fs-12">{errors.reason}</div>
            )}
          </div>
          {reason ===
            "other" && (
            <div className="form-group mb-3">
              <label className="text-black font-w500">Reason(English)</label>
              <textarea
                rows={5}
                className="form-control"
                value={textMessage}
                onChange={(e) => {
                  setTextMessage(e.target.value);
                  setErrors({ ...errors, textMessage: "" });
                }}
              />
              {errors.reason && (
                <div className="text-danger fs-12">{errors.reason}</div>
              )}
            </div>
          )}
        </div>
        <div className="modal-footer">
          <button type="button" onClick={onHide} className="btn btn-danger">
            <i className="flaticon-delete-1"></i> Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default ReasonForPicDelete;
